.line1, .line2, .line3, .line4, .line5
{
	stroke-dasharray:2000;
	stroke-dashoffset:2000;
    transform: translate(-130px, 130px);
}

.is-visible .line1,
.is-visible .line2,
.is-visible .line3,
.is-visible .line4,
.is-visible .line5{
    animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.image-animation, .text-animation {
    
     opacity: 0;
}

.is-visible .image-animation,
.is-visible .text-animation{
    animation: moveImage 0.5s linear forwards;
}


.is-visible .dealy-1{
	animation-delay: 1s;
}

.is-visible .dealy-2{
	animation-delay: 1.5s;
}

.is-visible .dealy-3{
	animation-delay: 2s;
}

.is-visible .dealy-4{
	animation-delay: 2.5s;
}

.is-visible .dealy-5{
	animation-delay: 3s;
}




@keyframes moveImage {
    from {
        transform: translateY(60%);
        opacity: 0;
    }
    to {
        transform: translateY(30%);
        opacity: 1;
    }
}