@import './variables.scss';
// @import './global.scss';
// $half-cirle: circle(100% at 25% 150%);

.homeService_curve {
  .homeService_left {
    clip-path: $half-cirle;
  }

  .homeService_right {
    clip-path: $half-cirle;
    transform: scaleX(-1);
  }
}

.explore--dutso {
	width: 100%;
	display: flex;
	justify-content: center;
	iframe {
		width: 100%;
		min-height: 300px;
		object-fit: cover;
		height: 100%;
	}
}

.homeservice_sec {
  padding-bottom: 250px;

  .homeService_pot {
    background-size: auto;
    background-position: top center;
    min-height: 700px;
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    

    .homeService_potWrapper {
      .pot_drop:first-child {
        padding: 0 100px 0 20px;
        // background-color: red;
      }
      .pot_drop:last-child {
        padding: 0 20px 0 100px;
        // background-color: blue;
      }
      
      .pot_drop {
        color: $color-primary;
        color: #0078AB;
        width: 350px;
        height: 230px;
        display: flex;
        align-items: center;
        
        background-size: 100%;
        background-repeat: no-repeat;   
        }
      :nth-child(odd) {
        p {
          text-align: right;
        }
      }
    }
  }
}


.homeservice_sec .homeService_pot > div {
  min-width: 100%;
}

.homeservice_sec .homeService_pot > div:last-child {
  max-width: 90%;
  min-width: 90%;
}
@media (max-width:1400px) {
  .homeservice_sec {
    .homeService_pot {
      max-width: 950px;
    background-size: 350px;
    background-position: center;
    }
  }
}

@media (max-width:991px) {

      .homeService_potWrapper {
        .pot_drop {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          background-image: none; 
          }
      }

      .homeservice_sec .homeService_pot .homeService_potWrapper .pot_drop {
        background-image: none !important;
        width: 100%;
        height: auto;
        padding: 20px !important;
        background-color: #febe0040 !important;
        backdrop-filter: blur(8px);
        text-align: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    
    .homeservice_sec .homeService_pot > div {
        display: flex;
        flex-direction: column;
        /* align-items: center !important; */
        /* justify-content: center !important; */
        margin: 0;
    }
    
    .homeservice_sec .homeService_pot > div:last-child {
        min-width: 100%;
        max-width: 100%;
        margin-top: 0;
    }
    
    .homeservice_sec .homeService_pot {
        padding-top: 0;
    }
    section.homeservice_sec {
      margin-top: 0;
  }
    .homeservice_sec .homeService_pot .homeService_potWrapper :nth-child(odd) p {
         text-align: center; 
    }

}
@media (max-width:625px) {
  .homeService_potWrapper {
    padding: 0 15px;
}
.homeService_potWrapper .pot_drop {
    border-radius: 15px;
}
section.homeservice_sec {
    background-color: #fff;
}
.homeservice_sec .homeService_pot {
    background-position: bottom;
    padding-bottom: 400px;
}
}

.homebrandin_wrapper {
  .homeService_curve {
    transform: translateX(0%) translateY(0%) rotate(-180deg);
  }
}



// .hero-blur {
//   transform: scale(0);
// }
.left-wave--svg {
  position: absolute;
  top: -12rem;
  left: 0;
  z-index: 1;
  display: none;
}

.ryt-wave--svg {
  position: absolute;
  right: 0;
  z-index: 1;
  top: -12rem;
  display: none;
}

.bottle {
  height: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 10px;
  bottom: -90px;
}

.bottle svg {
  border-top: 0;
  border-bottom: 0;
  background: #fead00;
}

.bg--linergradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #feb800, #fff);
  position: absolute;
  top: 0px;
  z-index: -1;
}
.ribon--line {
  background: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.button--icon:hover .icon--logo {
  animation: linear rotateAnimation 1s infinite; /* Apply the animation on hover */
}

@keyframes rotateAnimation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg); /* Rotate fully in 2 seconds */
  }
}

.icon--logo {
  transition: 0.3s;
}

.slick-slider {
  .slick-arrow {
    scale: 1.5;
  }
}



.borderbgTop {
  border-top: 130px solid transparent;
    padding: 15px;
  border-image: url("../images/curve-blog.svg") 130 stretch;
}
.bordered_content {
  transform: translateY(-60px);
  display: block;
}

.homebrandingbox_main {
  .slick-prev,.slick-next {
    transform: translateY(-40px);
    &::before {
      color: var(--color-primary);
    }
  }
  .slick-slide.slick-active.slick-current {
    // background-color: green;
    transform: scale(0.8);
    transform-origin: left top;
}

.slick-slide.slick-active.slick-current + .slick-slide.slick-active {
    // background-color: yellow;
    transform: scale(1.4);
    transform-origin: center;
}

// .slick-slide.slick-active.slick-current + .slick-slide.slick-active * .slick-slide {
//     // background-color: red;
//     opacity: .5;
// }

.slick-slide.slick-active.slick-current + .slick-slide.slick-active + .slick-slide {
    // background-color: red;
    transform: scale(0.8);
    transform-origin: right top;
}

.slick-slide {
  transition: ease 0.5s all;
  height: 385px;
  max-height: 385px;
  min-height: 385px;
  video {
    width: 100%;
  }
}
.slick-list > .slick-track {
  padding-top: 80px !important;
}

}

@media screen and (max-width: 1024px) {
  .homebrandingbox_main {
    .slick-slide.slick-active.slick-current {
      transform: unset;
  }
  
  .slick-slide.slick-active.slick-current + .slick-slide.slick-active {
      transform: unset;
  }
  
  .slick-slide.slick-active.slick-current + .slick-slide.slick-active + .slick-slide {
      transform: unset;
  }
  .slick-slide {
    transition: ease 0.5s all;
    height: auto;
    max-height: auto;
    min-height: auto;
  }
  .slick-list > .slick-track {
    padding-top: inherit !important;
  }
  }
}

.custom-prev-arrow, .custom-next-arrow {
  cursor: pointer;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.custom-prev-arrow:hover, .custom-next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}




.blog_list {
  grid-auto-flow: column;
  justify-content: center;
}
.blog_list > .blog_item {
  max-width: 20vw;
}
@media (max-width:1024px) and (min-width:640px) {
  .blog_list > .blog_item {
  max-width: 50vw;
}
}
@media (max-width:639px) {
  .blog_list {
      grid-auto-flow: row;
  }
  .blog_list > .blog_item {
  max-width: 100vw;
}
}

@media (max-width:1600px) and (min-width:1280px) {
  .HomeAbout_main .homeAbout {
    margin-bottom: -450px !important;
    padding-bottom: 450px !important;
  }
}

.postSingle_categoryList .active {
    background-color: #008000;
}