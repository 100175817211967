@import "./variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #{$color-primary};
    --color-sky: #{$color-sky};
    --color-input: #{$color-input};
    --color-orange: #{$color-orange};
    --color-red: #{$color-red};
    --color-blue: #{$color-blue};
    --color-green: #{$color-green};
    --color-green-light: #{$color-green-light};
    --half-cirle: #{$half-cirle};
    
  }
}

@layer utilities {
  .container {
    max-width: 1500px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.inter {
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
body {
  padding-top: 115px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  max-width: 100vw;
  overflow-x: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
}

$heading: 40px;

h1,
.heading1 {
  font-size: clamp(calc($heading - 10px), 3vw, $heading);
}

h2,
.heading2 {
  font-size: clamp(calc($heading - 16px), 2.8vw, calc($heading - 10px));
}

h3,
.heading3 {
  font-size: clamp(calc($heading - 18px), 2.6vw, calc($heading - 16px));
}

h4,
.heading4 {
  font-size: calc($heading - 20px);
}

h5,
.heading5 {
  font-size: calc($heading - 22px);
}

h6,
.heading5 {
  font-size: calc($heading - 24px);
}
a {
  cursor: pointer;
}
.mainHeader a.active {
  color: var(--color-primary);
}
.homebrandin_wrapper .slick-dots li button:before {
  font-size: 10px;
}
// header design start
.Header {
  // position: fixed;
  // width: 100%;
  // top: 0;
  // z-index: 100;

  .navBar {
    ul {
      .nav-item {
        ul.subMenu {
          a {
            padding: 10px 15px;
            display: block;
            border-bottom: 1px solid #eeeeee61;
          }
        }

        .submenu-active .dropdownmenu {
          opacity: 1;
        }

        li.submenu-active img {
          transform: rotate(176deg);
        }
      }
    }
  }
}

.menuRightpopup {
  position: fixed;
  width: 400px;
  height: 100vh;
  right: -400px;
  top: 0;
  z-index: 9999;
  transition: ease-in-out 0.5s;
}

.menuRightpopup.cart-visible {
  right: 0;
}

// header design end

// footer design start
.footerWrapper .dropdownmenu {
  display: none;
}

.footerWrapper .main-nav a img {
  opacity: 0;
}

.footerContainer .main--navigation {
  flex-direction: column;
  align-items: baseline;
}

.footerContainer .main--navigation a {
  color: #c1c1c1;
}

// footer design end


// header design start
.submenu-active .dropdownmenu {
  opacity: 1;
}

li.submenu-active img {
  transform: rotate(176deg);
}

.toggle-actve .main--navigation {
  transform: translateX(0px);
}

.toggle-actve .togle-contain .togle-line:nth-child(1) {
  transform: rotate(47deg);
  position: relative;
  top: 9px;
}

.toggle-actve .togle-contain .togle-line:nth-child(2) {
  transform: rotate(123deg);
}

.toggle-actve .togle-contain .togle-line:nth-child(3) {
  transform: rotate(139deg);
  opacity: 0;
}

.accordion-title {
	flex: 1;
}
.accordion-icon {
	margin-left: auto;
}

@media only screen and (max-width:1023px) {
  .NavBar-wrapper .dropdownmenu {
    min-width: max-content;
  }
}

@media only screen and (max-width:631px) {
  .footerContainer .main--navigation {
    align-items: center;
  }
}

// header design end

.ProductDetails_main {
  ul.slick-dots {
    li {
      button {
        position: relative;
        padding: 0;
        border: 1px solid #888;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:before {
          width: 5px;
          height: 5px;
          border: 2px solid #eee;
          border-radius: 50%;
          display: block;
          font-size: 10px;
          line-height: 5px;
          left: 2px;
          top: 6px;
        }
      }

      &.slick-active {
        button {
          border: 1px solid var(--color-primary);

          &:before {
            color: var(--color-primary);
          }
        }
      }
    }
  }
}

.slick-current {
	.culinaryBtn {
		opacity: 0;
	}
	+ {
		.slick-slide {
			.culinaryBtn {
				opacity: 0;
			}
		}
	}
}


.before\:bg-\[\#000000bf\]::before {
  background-color: #0000008a !important;
}

// products single
.BlogSingle_main {
  section.BlogSingle_wrapper{
    .bg-gradient-to-b::before {
      background-image: url("../images/spoon.png");
      content: "";
  width: 150px;
  left: -20px;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  top: 50px;
  opacity: 0.5;
  background-size: 100%;
    }
  }
  
  .postSingle_Related h4 {
    margin-top: 25px;
}
.RecipePlay_icon svg {
  font-size: 100px;
  color: #fff;
  cursor: pointer;
}
}
.productSingle_related .HomeProducts_title {
  display: none;
}

.productSingle_related .HomeProducts_Right {
  display: none;
}

.productSingle_related .HomeProducts_row .HomeProducts_Left {
  min-width: 100%
}

.productSingle_related .HomeProducts_row {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.productSingle_related .HomeProducts_row .HomeProducts_Left .HomeProducts_grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
}

// .productSingle_related .group {
//   background-color: lighten($color-orange, $amount: 45%);
// }

.productSlider_left,
.productSlider_left .slick-slider,
.productSlider_left .slick-list,
.productSlider_left .slick-list div {
  border-radius: 15px;
}

.BlogSingleHero_right {
  background-color: var(--color-red);
  padding: 30px;
  padding-right: 0;
  position: relative;
  overflow: hidden;

  img {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    width: 100%;
    height: calc(100% - 20px);
    position: absolute;
    background-color: #fff;
    left: 10px;
    top: 10px;
    // border-top-left-radius: 360px;
    // border-bottom-left-radius: 360px;
  }

  &::after {
    content: "";
    width: 100%;
    height: calc(100% - 40px);
    position: absolute;
    background-color: var(--color-blue);
    left: 20px;
    top: 20px;
    // border-top-left-radius: 360px;
    // border-bottom-left-radius: 360px;
  }
}
.ProductDetails_left * {
  border-radius: 0.75rem;
}
.postSingle_content {
 
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 25px;
    color: var(--color-primary);
  }
  
  p {
    margin-top: 25px;
  }
 
}

.postSingle_Related_list {
  li {
    border-bottom: 1px solid var(--color-sky);
    margin-bottom: 15px;

    &::before {
      background-image: url("../images/hero-circle.png");
      background-repeat: no-repeat;
    }
  }
}


// popup
.popup_tabs .tabs button {
  padding: 15px;
  background-color: transparent;
  width: 50%;
  border-radius: 10px 10px 0 0;
  color: var(--color-primary);
}
.popup_tabs .tabs {
  display: flex;
  justify-content: center;
  background-color: transparent;
  gap: 5px;
  max-width: 260px;
  margin: auto;
  button.active {
    background-color: white;
  }
}


// health page
.health_main {
  .health_bg {
    background-image: url('../images/health-bg.png');
  }
  .health_recipe {
    .bg-white {
      background-color: #F3F3F3;
    }
  }
  .slick-prev, .slick-next {
    &::before {
      color: #adadad;
    }
  }
}


.languageSelect {
	.ReactFlagsSelect-module_selectBtn__19wW7 {
		padding: 0;
		border: 0;
	}
	.ReactFlagsSelect-module_flagsSelect__2pfa2 {
		padding-bottom: 0;
	}
	.ReactFlagsSelect-module_selectOptions__3LNBJ {
		width: fit-content;
		border: 0;
		max-height: unset;
	}
}

.BlogSingleHero_right {
	&:hover {
		.play-button {
			img {
				opacity: 1 !important;
			}
		}
	}
}
.fullscreen-mode {
	video {
		border-radius: 0;
		transition: 0.5s;
	}
}


@media (max-width:1023px) {
  .BlogSingle_main section.BlogSingle_wrapper .bg-gradient-to-b::before {
    top: auto;
    bottom: 0;
    background-position: bottom;
    opacity: 0.3;
}
}
@media (min-width:992px) {
  body.overflow-y-hidden {
    padding-right: 15px;
  }
}
@media (max-width:991px) {
  .productSingle_related .HomeProducts_row .HomeProducts_Left .HomeProducts_grid.grid {
      grid-template-columns: 1fr 1fr;
  }
}
@media (max-width:625px) {
  .productSingle_related .HomeProducts_row .HomeProducts_Left .HomeProducts_grid.grid {
      grid-template-columns: 1fr;
  }
}

.mainHeader{
  background-color: #fff;
}

.element--circle {
  clip-path: polygon( 73.779% 0.074%,73.779% 0.074%,70.317% 0.388%,66.893% 0.929%,63.51% 1.695%,60.169% 2.687%,56.871% 3.904%,53.619% 5.345%,50.415% 7.009%,47.26% 8.897%,44.155% 11.007%,41.104% 13.338%,41.104% 13.338%,38.119% 15.886%,35.221% 18.628%,32.411% 21.563%,29.692% 24.688%,27.064% 28.004%,24.529% 31.507%,22.089% 35.196%,19.744% 39.07%,17.497% 43.126%,15.35% 47.365%,15.35% 47.365%,13.237% 51.931%,11.252% 56.647%,9.395% 61.508%,7.668% 66.513%,6.072% 71.659%,4.607% 76.943%,3.273% 82.362%,2.072% 87.914%,1.005% 93.597%,0.072% 99.406%,0.06% 99.456%,0.06% 99.456%,0.053% 99.508%,0.046% 99.56%,0.039% 99.612%,0.032% 99.665%,0.025% 99.719%,0.019% 99.773%,0.014% 99.828%,0.009% 99.884%,0.004% 99.941%,0% 100%,0.659% 100%,1.233% 100%,100% 100%,100% 7.696%,100% 5.692%,100% 5.122%,100% 5.122%,97.386% 4.036%,94.767% 3.079%,92.145% 2.252%,89.52% 1.553%,86.894% 0.984%,84.267% 0.543%,81.641% 0.232%,79.017% 0.05%,76.396% -0.002%,73.779% 0.074% );
      background: url('../images/white-box.png');
      width: calc(100% + 12px);
      height: 100%;
      background-size: cover;
      background-position-x: 0vw;
      background-repeat: no-repeat;
      top: 0px !important;
      transform: translate(5px, calc(3% - 1px));
      
  }
  
  .element--circle--2 {
  clip-path: polygon( 0% 5.122%,0% 5.692%,0% 7.696%,0% 100%,98.767% 100%,99.341% 100%,100% 100%,100% 100%,99.996% 99.941%,99.991% 99.884%,99.986% 99.828%,99.981% 99.773%,99.975% 99.719%,99.968% 99.665%,99.961% 99.612%,99.954% 99.56%,99.947% 99.508%,99.94% 99.456%,99.928% 99.406%,99.928% 99.406%,98.995% 93.597%,97.928% 87.914%,96.727% 82.362%,95.393% 76.943%,93.928% 71.659%,92.332% 66.513%,90.605% 61.508%,88.748% 56.647%,86.763% 51.931%,84.65% 47.365%,84.65% 47.365%,82.503% 43.126%,80.256% 39.07%,77.911% 35.196%,75.471% 31.507%,72.936% 28.004%,70.308% 24.688%,67.589% 21.563%,64.779% 18.628%,61.881% 15.886%,58.896% 13.338%,58.896% 13.338%,55.845% 11.007%,52.74% 8.897%,49.585% 7.009%,46.381% 5.345%,43.129% 3.904%,39.831% 2.687%,36.49% 1.695%,33.107% 0.929%,29.683% 0.388%,26.221% 0.074%,26.221% 0.074%,23.604% -0.002%,20.983% 0.05%,18.359% 0.232%,15.733% 0.543%,13.106% 0.984%,10.48% 1.553%,7.855% 2.252%,5.233% 3.079%,2.614% 4.036%,0% 5.122% );
      background: url('../images/white-box.png');
      width: calc(100% + 12px);
      height: 100%;
      background-size: cover;
      background-position-x: 0vw;
      background-repeat: no-repeat;
      top: 0px !important;
      transform: translate(8px, calc(3% - 1px));
      
  }

  .active--bg {
    background-color: var(--color-primary);
    color: #fff;
}

hr.eyeHr {
  border-top-width: 1px;
  filter: brightness(0%);
  transform: rotate3d(1, 1, 1, 45deg);
  top: -12px;
  position: relative;
  transition: 0.5s;
}

.explore--dutso--main .player__video{
width:100% !important;
height: auto !important;
aspect-ratio: 16/9;
}